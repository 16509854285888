
.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}
.modal {
    position: absolute;
    inset: 40% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 650px;
    width: 90vw;
    margin: 50px 0px;
}
@media max-width(400px) {
  .modal {
    position: absolute;
    inset: 40% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
    margin: 50px 0px;
  }
}
.close {
  position: absolute;
  right: 4px;
  top: 4px;
  background: none;
  /*color: rgba(255, 255, 255, 0.1);*/
  border: none;
  font-size: 18px;  
}
